<template>
  <div>
    <h4>Introduction and Acceptance of Terms</h4>

    <p>
      The following terms and conditions govern your access and use of this
      website and network of websites, which currently includes but is not
      limited to: Baraka Credit, Baraka Credit Kenya, Baraka Credit Uganda, Baraka Credit Tanzania
      (collectively, the “Sites” or individually, a “Site”). These terms and
      conditions also govern the content and services offered to you through the
      Site(s). By accessing, using, printing, installing, or downloading any
      material from any of the Sites, you agree to be bound by these Terms and
      Conditions. These Terms and Conditions are subject to change at any time
      at our discretion; your use of this Site(s) after such changes are
      implemented constitutes your acknowledgment and acceptance of the changes.
    </p>

    <p>
      If you do not agree to be bound by these Terms and Conditions, you may not
      enter any of the Sites, you must exit the Site immediately and you may not
      use or access any of the Sites or print or download any materials from
      them. You may use and access the Sites only in accordance with these Terms
      and Conditions. Please consult these Terms and Conditions regularly and
      read them carefully before using the Sites.
    </p>

    <p>
      You affirm that you have read this Agreement and understand, agree to be
      bound and consent to its Terms and Conditions. You are solely responsible
      for obtaining access to the Sites and that access may involve third party
      fees (such as Internet service provider or airtime charges); you are
      responsible for those fees. In addition, you must provide and are
      responsible for all equipment necessary to access the Sites and we will
      not be responsible for your inability to access and/or use the Website,
      either optimally or at all.
    </p>

    <h4>General</h4>

    <p>
      In these terms and conditions, the following terms will have the following
      meanings: - Baraka Credit Affiliates” Includes but is not limited to its
      officers, directors, shareholders, employees, independent contractors and
      agents
    </p>

    <p>“User” refers to the person who accesses and uses the Sites.</p>

    <p>
      Baraka Credit ” refers to Baraka Credit trade finance automation product and service.
    </p>

    <p>
      Baraka Credit ” refers to all Baraka Credit and its market segments, either
      individually and/or collectively as the context so permits.
    </p>

    <p>“We” “Us” “Our” refers to Baraka Credit as the context so requires.</p>

    <p>“You” “Your” refers to the User</p>

    <p>
      All products and services of Baraka Credit herein provided are subject to the
      terms and conditions of the applicable agreements governing their use.
      These terms and conditions are meant to regulate your access to this
      website and they are to be read together with the applicable terms and
      conditions governing any transaction(s), product(s) and/or service(s)
      provided in this website.
    </p>

    <h4>Use of Material</h4>

    <p>
      The contents of the Sites, including but not limited to text, artwork,
      names, logos, trademarks, service marks, software, video, music, sound,
      photographs, graphics, illustrations and other material
      (&quot;Material&quot;) are protected by the applicable copyright laws. You
      may only access and use the Sites, or any of the information or Materials
      provided on the Sites in a lawful manner. We authorize you to view and
      download a single copy of the Material on the Sites solely for your
      personal, non-commercial use. You may not sell or modify the Material or
      reproduce, display, distribute, or otherwise use the Material in any way
      for any non-private, public or commercial purpose without our written
      consent. If you violate any of these Terms, your permission to use the
      Material automatically terminates and you must immediately destroy any
      copies you have made of the Material.
    </p>

    <h4>Use of your Information and Privacy</h4>

    <p>
      We may hold and use your information provided by you in the course of
      accessing the Sites for a number of reasons, which may include but not
      limited to: - Monitoring your access for business purposes such as
      marketing, product research and development, customer service, prevention
      of unauthorized use of our systems; providing value added services;
      improving the content of the Sites; Carrying out activities in connection
      with a legal, governmental or regulatory requirement on the Bank, in
      connection with legal proceedings; or in respect of crime or fraud
      prevention, detection or prosecution. We will take all reasonable steps to
      use or disclose users&#39; personal information only in the manner stated
      in these Terms and Conditions. Personal information shall refer to all
      private information about an identifiable individual, such as a user&#39;s
      name, contact details, identity number, and age. Such information may be
      collected through various means such as feedback forms, forums, emails,
      etc. Some areas of the Sites may require the user to submit information in
      order for the user to register for certain services or benefits for
      example user’s name, contact details and identity number.
    </p>

    <p>
      You hereby agree that we may collect, use and store your personal
      information; we shall not disclose, for commercial gain or otherwise,
      users&#39; personal information other than as set out in these terms and
      conditions. We may disclose the information to our affiliates or to third
      parties contracted or employed by us to provide services for or to Baraka Credit,
      including for example, website hosting and development, partner banks,
      delivery services, and other support services. The third parties require
      access to users&#39; personal information to perform their functions and
      not for any other purposes and we will take all reasonable steps to ensure
      the confidentiality and non-disclosure of your personal information. We
      may also disclose your personal information to governmental agencies, and
      other regulatory or self-regulatory bodies if we are required to do so by
      law or if we believe that such action is necessary to comply with the law
      or with any legal process; protect and defend the rights, property or
      safety of Baraka Credit, its affiliates or their customers; prevent or deal with
      fraud or the abuse, misuse or unauthorized use of the Sites; and/or
      protect the rights, property or safety of members of the public (if a user
      provides false or deceptive information about him/herself or misrepresents
      him/herself as being someone else, we will proactively disclose such
      information to the appropriate regulatory bodies and commercial entities).
    </p>

    <p>
      You further agree that we may use your personal information to compile
      profiles for statistical purposes and may freely trade with such profiles
      and statistical data, provided that the profiles or statistical data
      cannot be linked to the user by a third party.
    </p>

    <p>
      You hereby acknowledge that Internet communications and transactions are
      not 100% secure or error free. In particular, information may not be
      secure in transit from you to our Sites. Where you provide particularly
      sensitive personal information such as passwords, ID numbers, or other
      sensitive information on this site, it is your responsibility to safeguard
      them.
    </p>

    <h4>Intellectual Property Rights</h4>

    <p>
      The material and content accessible from the Sites, and any other World
      Wide Web site owned, operated, licensed, or controlled by Baraka Credit as well
      as all trademarks, service marks, and logos displayed in the Sites
      (collectively, “Materials”) is the proprietary information and valuable
      intellectual property of the Trademark or the party that provided the
      Materials to the Trademark. The trademark retains all right, title, and
      interest in the Materials. Accordingly, the Materials may not be modified,
      copied, distributed, broadcast, displayed, licensed, transferred,
      republished, uploaded, posted, sold commercially or transmitted in any way
      without the prior written consent of the Trademark except that you may
      print out a copy of the Materials solely for your personal use. In doing
      so, you may not remove or alter, or cause to be removed or altered, any
      copyright, trademark, trade name, service mark, or any other proprietary
      notice appearing on any of the Materials. Modification or use of the
      content except as expressly provided in these Terms and Conditions
      violates the trademark’s intellectual property rights and you may be in
      breach of statutory or common law rights which could be the subject of
      legal action. Neither license, right, title nor intellectual property
      rights are transferred or granted to you by access to the Sites. All
      content included on the Sites, including but not limited to text,
      graphics, photographs, video and audio clips, streaming data, animation,
      images, downloadable materials, data compilations and software is the
      property of the Trademark or its content suppliers and is protected by
      applicable copyright and trademark laws.
    </p>

    <p>
      Baraka Credit disclaims all liability which may arise from any unauthorized
      reproduction or use of the contents of the Sites.
    </p>

    <h4>No Warranties</h4>

    <p>
      You expressly agree that use of the Sites or any Materials contained
      therein is at your own and sole risk. The materials and information in the
      Sites, including but not limited to services, products, information, data,
      text, graphics, links or other items, are provided by Baraka Credit on an
      &quot;as is&quot; and &quot;as available&quot; basis. References to
      material and information contained in the website include such material
      and information provided by third parties.
    </p>

    <p>
      We do not make any express or implied warranties, representations or
      endorsements including but not limited to any warranties of title,
      non-infringement of proprietary or third party rights, merchantability,
      usefulness, operation, completeness, timeliness, correctness, accuracy,
      satisfactory quality, reliability, fitness for a particular purpose in
      respect of the Sites, the material, information and/or functions therein
      and we expressly disclaim liability for errors and omissions in such
      materials, information and/or functions. Without derogation of the above
      and/or the terms and conditions of the applicable agreements governing all
      the products and services of Baraka Credit, reasonable measures will be taken by
      us to ensure the accuracy and validity of all information relating to
      transactions and products of Baraka Credit which originate exclusively from
      Masterpiece Fusion limited.
    </p>

    <p>
      Further, We do not warrant or represent that access to the whole or
      part(s) of this website, the materials, information and/or functions
      contained therein will be provided uninterrupted, timely, secure or error-
      free or that any identified defect will be corrected, or that there will
      be no delays, failures, errors or loss of transmitted information, that no
      viruses or other contaminating or destructive properties will be
      transmitted or that no damage will occur to your computer system. You
      understand and agree that any material and/or data downloaded or otherwise
      obtained through the use of the Sites or any of the materials contained
      therein is done at your own discretion and risk. If your use of the Sites
      or the Material results in the damage and need for servicing or replacing
      equipment or data, we shall not be responsible for those costs and you
      will solely be responsible for any such damages. If the Material is
      provided by third parties, we shall not be held responsible for any such
      third party material.
    </p>

    <p>
      The materials, information and functions provided in the Sites shall not
      under any circumstances be considered or construed as an offer or
      solicitation to sell, give, issue or as the giving of any advice in
      respect of any form of investment or other securities, loans, or deposits
      in any jurisdiction. You shall be responsible to evaluate the quality,
      adequacy, timeliness and usefulness of all services, content, advice,
      opinions and other information obtained or accessible through the Sites;
      further you should seek professional and/or legal advice at all times and
      obtain independent verification of the materials and information provided
      herein prior to making any investment, business or commercial decision
      based on any such materials or information.
    </p>

    <h4>Links to Third Party Sites</h4>

    <p>
      We may provide links to Third Party Websites. Such links do not
      necessarily imply any endorsement, agreement with, or support for, the
      information on or content of those Third Party Websites. We do not control
      the information or content on those Third Party Websites and neither we
      nor our Affiliates makes any warranties in respect of the content of these
      websites nor will be responsible or liable in any manner whatsoever, for
      your access, or inability to access, such Third Party Websites, or for any
      information or content, products or services made available on or through
      such Third Party Websites, or for any loss, liability or damage which you
      may suffer as a result of access to a Third Party Website through a link
      on the Website. You further acknowledge and agree that we shall not be
      responsible or liable, directly or indirectly, for any damage or loss
      caused or alleged to be caused by or in connection with use of or reliance
      on any such third party websites and access to any such third party sites
      is entirely at your own risk and subject to any terms and conditions and
      privacy policies posted therein.
    </p>

    <h4>Limitation of Liability</h4>

    <p>
      In no event shall Baraka Credit or its Affiliates, contract, negligence or any
      other basis including but not limited to direct, special, indirect,
      incidental, consequential, exemplary or punitive damages, damages for loss
      of profits, revenue, or good will, which may arise from your use or any
      person’s use, access, misuse or inability to use the Sites or any links to
      third party websites or any of the Materials contained therein, reliance
      on the information contained in the Sites, any technical, hardware or
      software failure of any kind, the interruption, error, omission, delay in
      operation, computer viruses, even if we have been advise of the
      possibility of such damages. Your use of the Web Site is at your own risk.
      If you are dissatisfied with any of the Materials or other contents of the
      Sites or with these Terms and Conditions or other policies, your sole
      remedy is to discontinue use of the Sites.
    </p>

    <h4>Indemnity</h4>

    <p>
      You hereby irrevocably agree to defend, indemnify, and hold harmless
      Baraka Credit, its officers, directors, shareholders, employees, independent
      contractors and agents, , or you under another person’s authority
      including without limitation to governmental agencies, use, misuse, or
      inability to use the Sites or any of the Materials contained therein, or
      your breach of any of these Terms and Conditions, any laws and regulations
      including but not limited to the Public Procurement and Asset Disposal
      Act, 2015 or any intellectual property right or proprietary right
      infringement claim made by a third party against Baraka Credit in connection with
      your use of this website. You warrant that all KYC Documents provided to
      Baraka Credit for use on the Site are legal and that you have full authority to
      use the Site as well as further agree to indemnify and hold harmless
      Baraka Credit and its Affiliates from and against any and all claims, actions,
      liabilities, costs, or demands, including without limitation legal and
      accounting fees, for all damages directly, indirectly, and/or
      consequentially resulting or allegedly resulting from your provision of
      all KYC documents to Baraka Credit or your authority or lack of it thereof to use
      the Site.
    </p>

    <p>
      We shall promptly notify you of any such claim, proceeding or suit as
      above, and you shall be responsible for its defense at your expense.
      Baraka Credit may participate in the defense of such claim or defense but is not
      obligated to do so.
    </p>

    <h4>Force Majeure</h4>

    <p>
      We shall not be responsible for any failure to perform due to unforeseen
      circumstances or to causes beyond our reasonable control, including but
      not limited to: acts of God, such as fire, flood, earthquakes or other
      natural disasters; war, riot, arson, embargoes, acts of civil or military
      authority, or terrorism; fiber cuts; strikes, or shortages in
      transportation, facilities, fuel, energy, labor or materials; failure of
      the telecommunications or information services infrastructure; hacking,
      SPAM, or any failure of a computer, server or software, for so long as
      such event continues to delay Baraka Credit’s Sites’ performance.
    </p>

    <h4>Severability, Non-Waiver and Entire Agreement</h4>

    <p>
      If any provision of these terms and conditions is found to be invalid by
      any court having competent jurisdiction, the invalidity of such provision
      shall not affect the validity of the remaining provisions of these terms
      and conditions, which shall remain in full force and effect. No waiver of
      any term of these terms and conditions shall be deemed a further or
      continuing waiver of such term or any other term. Except as expressly
      provided in a particular web page, these terms and conditions constitute
      the entire Agreement between you and us with respect to the use of the
      Sites.
    </p>

    <h4>Suspension and Termination of access</h4>

    <p>
      Baraka Credit reserves the right to terminate and/or suspend your access to the
      Sites and/or your use of the Sites at any time, for any reason. In
      particular, and without limitation, we may terminate and/or suspend your
      access should you violate any of these terms and conditions, or violate
      the rights of Baraka Credit, of any other user, or of any third party.
    </p>

    <h4>Governing Law and Jurisdiction</h4>

    <p>
      These terms and conditions and all matters arising out of or relating to
      these Terms and Conditions shall be governed by and are to be construed in
      accordance with the laws of Kenya, excluding any conflict of law
      provisions. By accessing this website and/or using the services provided
      herein by Baraka Credit, you hereby consent to the exclusive jurisdiction of the
      Kenyan courts in all disputes arising out of or relating to the use of
      this website.
    </p>
  </div>
</template>

<script>
export default {};
</script>
